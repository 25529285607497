<template>
  <v-row class="inner-content">
    <v-col>

      <v-card class="pt-2">
        <v-card-text>
          <p>Update the account list with new Google and Bing accounts.</p>
        </v-card-text>
      </v-card>

      <v-card>

        <v-card-actions class="pt-8 pb-8">
          <v-btn class="ml-5" color="primary" :loading="refreshing" @click="refresh()">Refresh</v-btn>

          <v-fade-transition mode="out-in">
            <v-btn color="green darken-1" text v-show="showRefreshed">Completed</v-btn>
          </v-fade-transition>
        </v-card-actions>

      </v-card>

    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'RefreshAccounts',

  data () {
    return {
      refreshing: false,
      showRefreshed: false,
      endpoint: '/a/core/accounts/refresh',
    }
  },

  methods: {
    refresh () {
      this.refreshing = true

      this.$http.post(this.endpoint, null).then(resp => {
        this.showRefreshed = true
        this.timer = setTimeout(() => { this.showRefreshed = false }, 3000)
        this.$store.dispatch('google/loadAccounts')
        .catch(errResp => {
          this.$store.commit('system/setAPIError', { method: 'GET', url: 'google/loadAccounts', description: errResp.response.data.err_description, ext_msg: errResp.response.data.external_message })
        })
        this.$store.dispatch('bing/loadAccounts')
        .catch(errResp => {
          this.$store.commit('system/setAPIError', { method: 'GET', url: 'bing/loadAccounts', description: errResp.response.data.err_description, ext_msg: errResp.response.data.external_message })
        })
      })
      .catch(errResp => {
        this.$store.commit('system/setAPIError', { method: 'POST', url: this.endpoint, description: errResp.response.data.err_description, ext_msg: errResp.response.data.external_message })
      })
      .finally(() => {
        this.refreshing = false
      })
    }
  },
}
</script>

<style scoped>
</style>